import React, { useEffect } from "react";
import NavBar from "../NavBar/NavBar";
import styles from "./Homepage.module.css";
import { motion as m } from "framer-motion";
import TextSpan from "../TextSpan";
import Aos from "aos";
import "aos/dist/aos.css";
import Particles from "react-tsparticles";
import DownloadIcon from "@mui/icons-material/Download";
import cv from "../../../Assets/balagaprasanthResume.pdf";
import Button from "@mui/material/Button";

import me from "../../../Assets/me.png";
import TagCloud from "TagCloud";
import { Link } from "react-router-dom";
import Music from "../../Music/Music";

export default function Homepage() {
  useEffect(() => {
    // return () => {
    const container = ".tagcloud";
    const texts = [
      "HTML",
      "CSS",
      "SASS",
      "JavaScript",
      "React",
      "Vue",
      "NextJS",
      "MongoDB",
      "NodeJS",
      "Babel",
      "Jquery",
      "ES6",
      "GIT",
      "GITHUB",
    ];

    const options = {
      radius: 300,
      maxSpeed: "normal",
      initSpeed: "normal",
      keep: true,
    };

    TagCloud(container, texts, options);
    // };
  }, []);

  const text1 = "Hi".split("");
  const text2 = "i'm Prasanth".split("");
  const text3 = "Full Stack WebDeveloper".split("");

  const spanVariants = {
    hover: {
      // y: [-1, -2, -2.8, 0.9, 0],
      // scaleY: [1, 1.3, 0.8, 1, 1, 2],
      color: "#00ccff",
      transform: [
        "scale3d(1,1,1)",
        "scale3d(1.4,.55,1)",
        "scale3d(.75,1.25,1)",
        "scale3d(1.25,.85,1)",
        "scale3d(.9,1.05,1)",
        "scale3d(1,1,1)",
      ],
      transition: {
        times: [0, 0.4, 0.6, 0.7, 0.8, 0.9],
      },
    },
  };
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  const downld = (url) => {
    const fileName = url;
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  return (
    <div className={styles.container}>
      <div className={styles.navbr}>
        <NavBar />
      </div>

      <div
        initial={{ y: "100%" }}
        animate={{ y: "0%" }}
        transition={{ duration: 0.75, ease: "easeOut" }}
        exit={{ opacity: 1 }}
        className={styles.homepagemaindiv}
        drag
      >
        <div className={styles.headSpanNav}>
          <div className={styles.headandspan}>
            <div data-aos="zoom-in" className={styles.homepagemaindiv2}>
              <m.div
                className={styles.homepage}
                animate={{ y: 0 }}
                initial={{ y: "100%" }}
                transition={{ delay: 0.3, duration: 0.5 }}
              >
                {text1.map((letter, index) => {
                  return (
                    <m.span
                      key={index}
                      variants={spanVariants}
                      initial="visible"
                      whileHover="hover"
                      className={styles.homepagespn}
                    >
                      {letter}
                    </m.span>
                  );
                })}
              </m.div>
              <m.div
                className={styles.homepage}
                animate={{ y: 0 }}
                initial={{ y: "100%" }}
                transition={{ delay: 0.6, duration: 0.5 }}
              >
                {text2.map((letter, index) => {
                  if (letter === "P")
                    return (
                      <m.span
                        key={index}
                        // variants={spanVariants}
                        initial="visible"
                        whileHover="hover"
                        style={{ color: "#40873f", fontSize: "4rem" }}
                        className={styles.spanP}
                      >
                        {letter}
                      </m.span>
                    );
                  else
                    return (
                      <m.span
                        key={index}
                        variants={spanVariants}
                        initial="visible"
                        whileHover="hover"
                        className={styles.homepagespn}
                      >
                        {letter}
                      </m.span>
                    );
                })}
              </m.div>
              <m.div
                className={styles.homepage}
                animate={{ y: 0 }}
                initial={{ y: "100%" }}
                transition={{ delay: 0.9, duration: 0.5 }}
              >
                {text3.map((letter, index) => {
                  return (
                    <m.span
                      key={index}
                      variants={spanVariants}
                      initial="visible"
                      whileHover="hover"
                      className={styles.homepagespn}
                    >
                      {letter}
                    </m.span>
                  );
                })}
              </m.div>
              <Button
                variant="contained"
                style={{ backgroundColor: "#00ccff" }}
                endIcon={<DownloadIcon />}
                className={styles.btn}
                onClick={() => {
                  downld(cv);
                }}
              >
                Download My CV
              </Button>
              <m.div
                className={styles.navlinka}
                endIcon={<DownloadIcon />}
                // className={styles.btn}
                onClick={() => {
                  downld(cv);
                }}
              >
                <a href="#">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  Download My CV
                </a>
              </m.div>
            </div>
            <div data-aos="zoom-out" className={styles.tagImageDiv}>
              <span className="tagcloud"></span>
              <div className={styles.imageDiv}>
                <img src={me} alt="prasanth" />
              </div>
            </div>
          </div>
          {/* <div className={styles.navlinks}>
            <Link to={"/"}>
              <p>Home</p>
            </Link>
            <Link to={"/about"}>
              <p>About</p>
            </Link>
            <Link to={"/projects"}>
              <p>Projects</p>
            </Link>
            <Link to={"/mail-me"}>
              <p>Mail Me</p>
            </Link>
            <Link to={"/resume"}>
              <p>Resume</p>
            </Link>
          </div> */}
        </div>
      </div>
      {/* <a
        href="https://api.whatsapp.com/send?phone=51955081075&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202."
        target="_blank"
        rel="noreferrer"
        // onClick={handleOnClick}
      >
        <i class="fa fa-whatsapp my-float" style={{ marginTop: "16px" }}></i>
      </a> */}
      {/* <div className={styles.float}> */}
      {/* <Music /> */}
      {/* </div> */}
    </div>
  );
}
