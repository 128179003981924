import React, { useEffect } from "react";
import styles from "./StaticProjects.module.css";
import sendfast from "../../../Assets/sendfast.png";
import tekkrexim from "../../../Assets/tekkrexim.png";
import foxxmile from "../../../Assets/foxxmile.png";
import truckae from "../../../Assets/truckae.png";
import trukrr from "../../../Assets/trukrr.png";
import tekkrinnovations from "../../../Assets/tekkrinnovations.png";
import tekkreggs from "../../../Assets/tekkreggs.png";
import farm29 from "../../../Assets/farm29.png";
import edapharma from "../../../Assets/edapharma.png";
import greenwheez from "../../../Assets/greenwheez.png";
import Aos from "aos";
import "aos/dist/aos.css";
import Tilt from "react-parallax-tilt";

export default function StaticProjects() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const staticProject = [
    {
      id: 1,
      image: sendfast,
      name: "Sendfast",
      url: "http://www.sendfast.in/",
    },
    {
      id: 2,
      image: tekkrexim,
      name: "Tekkrexim",
      url: "http://tekkrexim.in/",
    },
    {
      id: 3,
      image: foxxmile,
      name: "Foxxmile",
      url: "http://foxxmile.com/",
    },
    {
      id: 4,
      image: truckae,
      name: "Truckae",
      url: "https://truckae.com/",
    },
    {
      id: 5,
      image: trukrr,
      name: "Trukrr",
      url: "http://trukrr.com/",
    },
    {
      id: 6,
      image: tekkrinnovations,
      name: "Tekkrinnovations",
      url: "https://tekkrinnovations.com/",
    },
    {
      id: 7,
      image: farm29,
      name: "Farm29",
      url: "https://farm29.com/",
    },
    {
      id: 8,
      image: tekkreggs,
      name: "Tekkreggs",
      url: "http://tekkreggs.com/",
    },
    {
      id: 9,
      image: edapharma,
      name: "Edapharma",
      url: "http://www.edapharma.com/",
    },
    {
      id: 10,
      image: greenwheez,
      name: "Greenwheez",
      url: "http://greenwheez.com/",
    },
  ];
  return (
    <div className={styles.staticprojectsdiv}>
      <div className={styles.statich33}>
        <h2>Some Static Sites</h2>
      </div>
      <div className={styles.staticprojectsdiv2}>
        {staticProject.map((project, i) => (
          <Tilt
            glareEnable={true}
            tiltMaxAngleX={10}
            tiltMaxAngleY={10}
            perspective={1000}
            glareColor={"green"}
          >
            <a href={project.url} target="_blank" rel="noreferrer">
              <div className={styles.staticprojects}>
                <div className={styles.staticprimage}>
                  <img src={project.image} alt={project.name} />
                </div>
                <div className={styles.staticpp}>
                  <p>{project.name}</p>
                </div>
              </div>
            </a>
          </Tilt>
        ))}
      </div>
    </div>
  );
}
