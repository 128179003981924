import React from "react";
import styles from "./Skills.module.css";
import reactlogo from "../../../Assets/reactlogo.png";
import nodelogo from "../../../Assets/nodelogo.png";
import pythonlogo from "../../../Assets/pythonlogo.png";
import mongo from "../../../Assets/mongo.png";
import awslogo from "../../../Assets/awslogo.png";
import django from "../../../Assets/django.png";
import html from "../../../Assets/html.png";
import csslogo from "../../../Assets/csslogo.png";
import javascriptlogo from "../../../Assets/javascriptlogo.png";
import nextjs from "../../../Assets/nextjs.png";
import tailwindlogo from "../../../Assets/tailwindlogo.png";
import flutterlogo from "../../../Assets/flutterlogo.png";
import Tilt from "react-parallax-tilt";

export default function Skills() {
  const skill = [
    {
      id: 1,
      image: reactlogo,
      name: "ReactJs",
      style: {
        boxShadow: "5px 5px 10px green, -5px -5px 10px #535e73",
      },
    },
    {
      id: 2,
      image: nodelogo,
      name: "NodeJs",
      style: "5px 5px 10px blue, -5px -5px 10px #535e73",
    },
    {
      id: 3,

      image: pythonlogo,
      name: "Python",
    },
    {
      id: 4,

      image: mongo,
      name: "MongoDB",
    },
    {
      id: 5,

      image: awslogo,
      name: "AWS",
    },
    {
      id: 6,

      image: django,
      name: "Django",
    },
    {
      id: 7,

      image: html,
      name: "HTML",
    },
    {
      id: 8,

      image: csslogo,
      name: "CSS",
    },
    {
      id: 9,

      image: javascriptlogo,
      name: "JavaScript",
    },
    {
      id: 10,

      image: nextjs,
      name: "NextJS",
    },
    {
      id: 11,

      image: tailwindlogo,
      name: "TailwindCss",
    },
    {
      id: 12,

      image: flutterlogo,
      name: "Flutter",
    },
  ];
  return (
    <div className={styles.skills}>
      <div className={styles.skillsh2}>
        <h2>Skills</h2>
      </div>
      <div className={styles.skillsdiv}>
        {skill.map((sk, i) => (
          <Tilt
            glareEnable={true}
            tiltMaxAngleX={10}
            tiltMaxAngleY={10}
            perspective={1000}
            glareColor={"green"}
            div
            className={styles.maindivimgg}
          >
            <div key={i} className={styles.skillsNeo}>
              <div className={styles.skillsNeodiv}>
                <div className={styles.skillsNeoimage}>
                  <img src={sk.image} alt={sk.name} />
                </div>
                <div className={styles.skillsNeoname}>
                  <p>{sk.name}</p>
                </div>
              </div>
            </div>
          </Tilt>
        ))}
      </div>
    </div>
  );
}
