import "./App.css";
import HomePage from "./Components/Home/Homepage/Homepage";
import About from "./Components/AboutUsPage/About/About";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./Components/Home/NavBar/NavBar";
import Projects from "./Components/ProjectsPage/Projects/Projects";
import ProjectsPage from "./Components/ProjectsPage/ProjectsPage";
import Quote from "./Components/Quote/Quote";
import Resume from "./Components/Resume/Resume";
import Music from "./Components/Music/Music";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <div className="navroutes">
          <div className="navbar"> */}
        {/* <NavBar /> */}
        {/* </div> */}
        {/* <div className="routes"> */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/mail-me" element={<Quote />} />
          {/* <Route path="/Resume" element={<Resume />} /> */}
        </Routes>
        <Music />
        {/* </div>
        </div> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
