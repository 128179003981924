import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import styles from "./Quote.module.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import contactus from "../../Assets/Smoke.json";
import Lottie from "react-lottie";
import NavBar from "../Home/NavBar/NavBar";
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import Particles from "react-tsparticles";
import contactus2 from "../../Assets/contactus.json";
import Music from "../Music/Music";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";

export default function Quote() {
  const form = useRef();
  const [succuss, setSuccuss] = useState("");
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: contactus,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: contactus2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "greenwheez",
        "template_hi5nr4e",
        form.current,
        "user_IhmpA7M4EisiaIeAKNlSu"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("ssss");
          setSuccuss(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccuss("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [succuss]);

  return (
    <>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "#383f4d",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#a4a7a9",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              directions: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: ["image"],
              image: [
                {
                  src: "https://seeklogo.com/images/R/react-logo-7B3CE81517-seeklogo.com.png",
                  height: 20,
                  width: 23,
                  opacity: 0.2,
                },
                {
                  src: "https://seeklogo.com/images/N/nodejs-logo-FBE122E377-seeklogo.com.png",
                  height: 20,
                  width: 20,
                  opacity: 0.2,
                },
                {
                  src: "https://seeklogo.com/images/M/mongodb-logo-655F7D542D-seeklogo.com.png",
                  height: 20,
                  width: 20,
                  opacity: 0.2,
                },
              ],
            },
            size: {
              value: 15,
            },
          },
          detectRetina: true,
        }}
      />{" "}
      <div
        style={{
          // backgroundColor: "#202124",
          position: "relative",
          zIndex: "9999",
          width: "100%",
        }}
      >
        <NavBar showHome={"showHome"} />
      </div>
      <div className={styles.quote}>
        <div className={styles.quoteconct}>
          <Lottie
            options={defaultOptions2}
            loop={false}
            style={{ color: "red" }}
          />
        </div>
        <div className={styles.quote2}>
          <div className={styles.quote21}>
            <a href="mailto:prasanth.bornskipper@gmail.com">
              <span>
                <EmailIcon />
              </span>{" "}
              <span>prasanth.bornskipper@gmail.com</span>
            </a>
            <br />
            <a
              href="https://wa.me/9391281091?text=Hello"
              target="_blank"
              rel="noreferrer"
            >
              <span>
                <WhatsAppIcon />
              </span>{" "}
              <span>9391281091</span>
            </a>
          </div>
        </div>
      </div>
      {/* <Music /> */}
    </>
  );
}
