import React, { useState, useRef, useEffect } from "react";
import styles from "./Music.module.css";
import musicJson from "../../Assets/Scene.json";
import Lottie from "react-lottie";
import music from "../../Assets/music.mp3";
import music2 from "../../Assets/headphones.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import { Link } from "react-router-dom";

export default function Music(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: musicJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [audioStatus, changeAudioStatus] = useState(false);
  const myRef = useRef();

  const startAudio = () => {
    myRef.current.play();

    changeAudioStatus(true);
  };

  const pauseAudio = () => {
    console.log("here");
    myRef.current.pause();
    changeAudioStatus(false);
  };

  return (
    <div className={styles.float}>
      <div className={styles.floatlinked}>
        <a
          href="https://www.linkedin.com/in/prasanth-balaga-84095924/"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon className={styles.floatlinked2} />
        </a>
        <a
          href="https://gitlab.com/powersanth"
          target="_blank"
          rel="noreferrer"
        >
          <GitHubIcon className={styles.floatlinked2} />
        </a>
      </div>
      <div className={styles.lottie}>
        <audio ref={myRef} src={music} />
        {audioStatus ? (
          <div onClick={pauseAudio}>
            <Lottie
              options={defaultOptions}
              loop={false}
              style={{ color: "red" }}
            />
          </div>
        ) : (
          <img
            className={styles.musicpause}
            onClick={startAudio}
            src={music2}
            alt="music2"
          />
        )}
      </div>
    </div>
  );
}
