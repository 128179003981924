import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import reactlogo from "../../../Assets/reactlogo.png";
import styles from "./Projects.module.css";
import Particles from "react-tsparticles";
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import NavBar from "../../Home/NavBar/NavBar";
import { IoLogoReact } from "react-icons/fa";
import ProductParticle from "../ProductParticle";
import Music from "../../Music/Music";

export default function Projects() {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);
  return (
    <div className={styles.container}>
      {/* <ProductParticle></ProductParticle> */}{" "}
      <div className={styles.navbr}>
        <NavBar />
      </div>
      <div className={styles.projectsdiv}>
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            background: {
              color: {
                value: "#383f4d",
              },
            },
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#ffffff",
              },
              links: {
                color: "#a4a7a9",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                directions: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: ["image"],
                image: [
                  {
                    src: "https://seeklogo.com/images/R/react-logo-7B3CE81517-seeklogo.com.png",
                    height: 20,
                    width: 23,
                    opacity: 0.2,
                  },
                  {
                    src: "https://seeklogo.com/images/N/nodejs-logo-FBE122E377-seeklogo.com.png",
                    height: 20,
                    width: 20,
                    opacity: 0.2,
                  },
                  {
                    src: "https://seeklogo.com/images/M/mongodb-logo-655F7D542D-seeklogo.com.png",
                    height: 20,
                    width: 20,
                    opacity: 0.2,
                  },
                ],
              },
              size: {
                value: 15,
              },
            },
            detectRetina: true,
          }}
        />
        <div className={styles.projectsdivh2}>
          <h2>My Work Experience</h2>
        </div>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "rgb(97 176 72)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="2023 - present"
            iconStyle={{ background: "rgb(97 176 72)", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              <span className={styles.headh3}>Flypigeon</span>,
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Lead Frontend Developer
            </h4>
            <p>A B2B and B2C booking platform for flights, buses, hotels.</p>
            <ul>
              <li>
                Collaborated with business & UI/UX team on different application
                workflows
              </li>
              <li>
                Built complex reusable components such as
                AutoCompleteInput(based on api data), Stepper from scratch to
                build B2B and B2C interfaces
              </li>
              <li>
                Optimised Filters,Sort functionality on results by Windowing,
                Lazy Loading/Rendering techniques
              </li>
              <li>Applied code splitting to reduce page load time</li>
              <li>Used debouncing / abort to avoid unneeded api calls</li>
              <li>Added frontend caching to improve performance</li>
              <li>
                Integrated with Travel API Providers, GDS, Payment Gateway,
              </li>
              <li>SMS, WhatsApp, Email APIs.</li>
              <li>
                Tools: react, redux, materialUI, react router, emotion, Formik
              </li>
              <p className={styles.projectp}>
                Skills:{" "}
                <span className={styles.projectspan}>
                  React Router · Formik · MaterialUI · Trello · API Development
                  · Amazon Web Services (AWS) · Cascading Style Sheets (CSS) ·
                  React.js · Redux.js
                </span>
              </p>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "rgb(0 204 255)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="2022 - 2023"
            iconStyle={{ background: "rgb(0 204 255)", color: "#fff" }}
            //   icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              <span className={styles.headh32}>Farm29</span>,
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Lead Frontend Developer
            </h4>
            <p>
              A grocery web application based on the next day delivery model.
              Users can buy an item(s) using regular order flow or subscribe for
              the item. Farm29 also offers a few monthly combo packs.
            </p>
            <ul>
              <li>Involved in developing API schema</li>
              <li>Main features: cart, monthly pack, subscription, coupons,</li>

              <li>
                Admin web panels for Superuser, Warehouse managers, Business
                team, Support
              </li>
              <li>Implemented custom Paytm payment gateway</li>
              <li>Mentored 2 frontend developers</li>
              <li>Google Maps API integration</li>
              <p className={styles.projectp}>
                Skills:{" "}
                <span className={styles.projectspan}>
                  React Router · Formik · MaterialUI · Trello · API Development
                  · Amazon Web Services (AWS) · Cascading Style Sheets (CSS) ·
                  React.js · Redux.js
                </span>
              </p>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "rgb(97 176 72)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="2022 - 2022 April"
            iconStyle={{ background: "rgb(97 176 72)", color: "#fff" }}
            //   icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              <span className={styles.headh3}>TekkrOrganics</span>,
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Lead Frontend Developer
            </h4>
            <p>An online organic fruits store for seasonal fruits.</p>
            <ul>
              <li>
                Developed this project from scratch so involved in all phases of
                SDLC, important features include wishlist, cart, order flow,
                etc.
              </li>
              <li>
                Used react Context to share the state across component tree
              </li>
              <li>Deployed frontend using AWS S3</li>
              <li>Integrated with Razorpay(Payment gateway)</li>
              <p className={styles.projectp}>
                Skills:{" "}
                <span className={styles.projectspan}>
                  React Router · Formik · MaterialUI · Trello · API Development
                  · Amazon Web Services (AWS) · Cascading Style Sheets (CSS) ·
                  React.js · Redux.js
                </span>
              </p>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2021 - 2022"
            contentStyle={{ background: "rgb(0 204 255)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            iconStyle={{ background: "rgb(0 204 255)", color: "#fff" }}
            //   icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              <span className={styles.headh32}>Ration Delivery</span>,
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Full Stack Developer
            </h4>
            <p>A web application for Direct to Door Ration Delivery System.</p>
            <ul>
              <li>
                Developed web interfaces for superusers, warehouse managers,
                delivery executives.
              </li>
              <li>
                Coordinated with the backend team to incorporate the business
                logic into the UI.
              </li>
              <li>Wrote Unit tests for better code coverage</li>
              <p className={styles.projectp}>
                Skills:{" "}
                <span className={styles.projectspan}>
                  React Router · Formik · MaterialUI · Trello · API Development
                  · Amazon Web Services (AWS) · Cascading Style Sheets (CSS) ·
                  React.js · Redux.js
                </span>
              </p>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{ background: "rgb(97 176 72)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="2020 - 2021"
            iconStyle={{ background: "rgb(97 176 72)", color: "#fff" }}
            //   icon={<SchoolIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              <span className={styles.headh3}>CMS</span>,
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Full Stack Developer
            </h4>
            <p>
              Cash Management System for tracking the cash collected from
              government controlled liquor shops in the state of Andhra Pradesh
              (AP Government).
            </p>
            <ul>
              <li>
                Developed responsive UI for users with different access levels
                e.g. Cash pickup executives, Store supervisors, administrators.
              </li>
              <li>
                Used websockets for real time updates about cash pickup &
                deposits
              </li>

              <p className={styles.projectp}>
                Skills:{" "}
                <span className={styles.projectspan}>
                  React Router · Formik · MaterialUI · Trello · API Development
                  · Amazon Web Services (AWS) · Cascading Style Sheets (CSS) ·
                  React.js · Redux.js · Django
                </span>
              </p>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{ background: "rgb(0 204 255)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="2019 - 2020"
            iconStyle={{ background: "rgb(0 204 255)", color: "#fff" }}
            //   icon={<SchoolIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              <span className={styles.headh32}>Sendfast</span>,
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Lead Frontend Developer
            </h4>
            <p>A last-mile logistics service provider.</p>
            <ul>
              <li>Developed an employee payroll management platform</li>
              <li>
                Built intuitive easy-to-use interfaces for critical business
                functionalities e.g. employee onboarding, complex pay/salary
                models using angularjs directives
              </li>
              <li>Created Email templates using HTML Tables</li>
              <p className={styles.projectp}>
                Skills:{" "}
                <span className={styles.projectspan}>
                  React Router · Formik · MaterialUI · Trello · API Development
                  · Amazon Web Services (AWS) · Cascading Style Sheets (CSS) ·
                  React.js · Redux.js
                </span>
              </p>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{ background: "rgb(97 176 72)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date="2018 - 2019"
            iconStyle={{ background: "rgb(97 176 72)", color: "#fff" }}
            //   icon={<SchoolIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              <span className={styles.headh3}>3endk</span>,
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Lead Frontend Developer
            </h4>
            <p>
              Debugged and developed new features for an existing set of android
              apps(for merchant/restaurant, customer, delivery executive) for a
              food delivery business based in Dubai.
            </p>
            <ul>
              <li>Writing Unit test cases for better code coverage</li>
              <li>Deploying changes on Amazon Web Services.</li>
              <li>
                Involved in requirement analysis, implementation, documentation
                and status reports.
              </li>
              <li>Involved in UI and Database Design.</li>
              <li>
                Involved in Defect Analysis and Defect Fixing by providing the
                quick solutions.
              </li>
              <li>
                Created many components like Button, Checkbox, Circular
                Progress, Data Picker, Date Range Picker, Horizontal Stepper,
                Login, Month Picker, tabs, tooltip, Vertical Stepper etc
              </li>
              <p className={styles.projectp}>
                Skills:{" "}
                <span className={styles.projectspan}>
                  React Router · Formik · MaterialUI · Trello · API Development
                  · Amazon Web Services (AWS) · Cascading Style Sheets (CSS) ·
                  React.js · Redux.js
                </span>
              </p>
            </ul>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
      {/* <Music /> */}
    </div>
  );
}
