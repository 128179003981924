import React from "react";
import NavBar from "../../Home/NavBar/NavBar";
import styles from "./About.module.css";
import { motion as m } from "framer-motion";
import Particles from "react-tsparticles";
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import AboutUsContent from "../AboutUsContent/AboutUsContent";
import Skills from "../Skills/Skills";
import Music from "../../Music/Music";

export default function About() {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.navbr}>
        <NavBar />
      </div>
      <div
        initial={{ y: "100%" }}
        animate={{ y: "0%" }}
        transition={{ duration: 0.75, ease: "easeOut" }}
        exit={{ opacity: 1 }}
        className={styles.AboutMainDiv}
      >
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            // background: {
            //   color: {
            //     value: "#0d47a1",
            //   },
            // },
            fpsLimit: 120,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#ffffff",
              },
              links: {
                color: "#a4a7a9",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                directions: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: ["image"],
                image: [
                  {
                    src: "https://seeklogo.com/images/R/react-logo-7B3CE81517-seeklogo.com.png",
                    height: 20,
                    width: 23,
                    opacity: 0.2,
                  },
                  {
                    src: "https://seeklogo.com/images/N/nodejs-logo-FBE122E377-seeklogo.com.png",
                    height: 20,
                    width: 20,
                    opacity: 0.2,
                  },
                  {
                    src: "https://seeklogo.com/images/M/mongodb-logo-655F7D542D-seeklogo.com.png",
                    height: 20,
                    width: 20,
                    opacity: 0.2,
                  },
                ],
              },
              size: {
                value: 15,
              },
            },
            detectRetina: true,
          }}
        />
        <AboutUsContent />
        <Skills />
      </div>
      {/* <Music /> */}
    </div>
  );
}
