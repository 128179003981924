import React from "react";
import styles from "./AboutUsContent.module.css";
import me2 from "../../../Assets/aboutus.png";

export default function AboutUsContent() {
  return (
    <div className={styles.aboutuscontentmaindiv}>
      <div className={styles.aboutuscontentmaindiv2}>
        <div className={styles.aboutuscontentimage}>
          <img src={me2} alt="me2" />
        </div>
        <div className={styles.aboutuscontentdiv}>
          <p>
            I am Balaga Prasanth, a React developer with over 5 years of
            experience in developing web applications using agile methodologies.
            I graduated from <span> IIT Kharagpur </span> and have a proven
            track record of building applications from scratch, including the
            development of APIs for mobile and UI components. Currently, I am
            working as a tech lead at SendFast, where I lead a team of
            developers in delivering high-quality software solutions. My role
            involves overseeing the development process, providing technical
            guidance, and ensuring the successful implementation of projects.
          </p>
        </div>
      </div>
      <ul className={styles.aboutuscontentdiv2}>
        <li>
          Throughout my career, I have gained extensive experience in
          requirement gathering, development, and troubleshooting of web
          applications. I excel at starting applications from scratch, handling
          both frontend and backend development. This comprehensive knowledge
          allows me to create seamless and efficient solutions that meet client
          requirements.
        </li>
        <li>
          I have a strong command of front-end and back-end development
          technologies, and I am well-versed in deploying web-based applications
          using AWS (EC2 & S3). My proficiency extends to programming languages
          and frameworks such as
          <span>
            {" "}
            React, Python, Django, Redis, Node.js, MongoDB, and Celery
          </span>
          . I leverage these tools to build scalable and robust applications
          that deliver exceptional user experiences.
        </li>
        <li>
          Troubleshooting and debugging are integral parts of my skill set, and
          I have successfully resolved complex issues in production-level code.
          My ability to quickly identify and rectify problems ensures smooth and
          efficient application performance.
        </li>
        <li>
          In my work, I adhere to Agile and Scrum methodologies, collaborating
          closely with cross-functional teams to deliver results within project
          timelines. I possess excellent communication skills, enabling me to
          effectively interact with stakeholders, understand their requirements,
          and translate them into actionable development tasks.
        </li>
        <li>
          With my wealth of technical expertise, hands-on experience, and
          commitment to delivering excellence, I am confident in my ability to
          contribute to the success of any web development project.
        </li>
      </ul>
    </div>
  );
}
