import React, { useEffect } from "react";
import Projects from "./Projects/Projects";
import StaticProjects from "./StaticProjects/StaticProjects";
import particleConfig from "../Config/Config.js";
import Particles from "react-tsparticles";
import Aos from "aos";
import "aos/dist/aos.css";

export default function ProjectsPage() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      {/* <Particles params={particleConfig}></Particles> */}

      <Projects />
      <div>
        <StaticProjects />
      </div>
    </>
  );
}
